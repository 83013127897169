import React from 'react';
import './LandingPage.css';
import { Grid, Typography } from "@mui/material";
import logo from './../../resources/logo.png';

export const LandingPage = () => {

  const scrollToMenu = () => {
    const menuSection = document.getElementById("menu-section");
    if (menuSection) {
      menuSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="landing-page">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="grid-container"
      >
        <div className="logo-container">
          <img src={logo} alt="logo" className="logo-img" />
        </div>
        <div className="footer" onClick={scrollToMenu} id="menu-section">
          <Typography variant="h4" className="menu-title">
            Menu
          </Typography>
        </div>
      </Grid>
    </div>
  );
};

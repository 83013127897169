import React from 'react';
import {Grid} from '@mui/material';
import {FoodCategoryCard} from "./FoodCategoryCard";
import {menu} from "../../resources/menu";
import './MenuPage.css';


export const MenuPage = () => {
  return (
    <div className="menu-page">
      <Grid container spacing={2}>
        {menu.map((category, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <FoodCategoryCard category={category}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};


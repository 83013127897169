import React from 'react';
import {Grid, Typography} from '@mui/material';

export const AboutPage: React.FC = () => {
  return (
    <section className="content-section">
      <div className="content">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <div>
              <Typography variant="h5" gutterBottom>Godziny otwarcia</Typography>
              <Typography variant="body1" gutterBottom>Poniedziałek - Piątek: 14:00 - 20:00</Typography>
              <Typography variant="body1" gutterBottom>Sobota: 10:00 - 24:00</Typography>
              <Typography variant="body1" gutterBottom>Niedziela: 10:00 - 22:00</Typography>
              <Typography variant="body2" gutterBottom>Podane godziny mogą ulec zmianie zależnie od warunków
                pogodowych</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <Typography variant="h5" gutterBottom>Lokalizacja</Typography>
              <Typography variant="body1" gutterBottom>Droga Męczenników Rotundy</Typography>
              <Typography variant="body1" gutterBottom>22-400 Zamość</Typography>
              <Typography variant="body1" gutterBottom>(Zalew Zamojski)</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <Typography variant="h5" gutterBottom>Kontakt</Typography>
              <Typography variant="body1" gutterBottom>Firma Produkcyjno Handlowa BARTEK</Typography>
              <Typography variant="body1" gutterBottom>Jadwiga Popielec</Typography>
              <Typography variant="body1" gutterBottom>Ul. Chmielna 70</Typography>
              <Typography variant="body1" gutterBottom>22-448 Michalów</Typography>
              <Typography variant="body1" gutterBottom>NIP: 922-121-38-22</Typography>
              <Typography variant="body1" gutterBottom>Tel: +48 504 131 416</Typography>
              <Typography variant="body1" gutterBottom>Email: laspalmaszamosc@gmail.com</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

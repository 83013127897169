import React from 'react';
import './App.css';
import {LandingPage} from "./views/landing/LandingPage";
import {MenuPage} from "./views/food/MenuPage";
import {AboutPage} from "./views/footer/AboutPage";

function App() {

  return (
    <div className="App">
      <LandingPage></LandingPage>
      <MenuPage></MenuPage>
      <AboutPage></AboutPage>
    </div>
  );
}

export default App;

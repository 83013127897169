export interface FoodItem {
  name: string;
  price: number;
}

export interface FoodCategory {
  name: string;
  items: FoodItem[];
}

export const menu: FoodCategory[] = [
  {
    name: 'Lody',
    items: [
      {name: 'Lody Włoskie Duże', price: 8},
      {name: 'Lody Włoskie Małe', price: 6},
      {name: 'Gałka', price: 6},
      {name: 'Deser Lodowy', price: 12},
      {name: 'Twister', price: 11},
    ]
  },
  {
    name: 'Gofry',
    items: [
      {name: 'Gofr (pusty)', price: 5},
      {name: 'Cukier puder', price: 1},
      {name: 'Bita śmietana', price: 4},
      {name: 'Owoce', price: 5},
      {name: 'Nutella', price: 4},
      {name: 'Frużelina', price: 4},
      {name: 'Polewa', price: 2},
      {name: 'Posypka', price: 3},
    ]
  },
  {
    name: 'Zapiekanki',
    items: [
      {name: 'Klasyczna (pieczarki, ser)', price: 15},
      {name: 'Z warzywami (pieczarki, ser, rukola, pomidorki)', price: 18},
    ]
  },
  {
    name: 'Hot Dog',
    items: [
      {name: 'Francuski (parówka, sos)', price: 8},
      {name: 'Amerykański (parówka, sos, warzywa)', price: 14},
    ]
  },
  {
    name: 'Burgery',
    items: [
      {name: 'Klasyczny (Wołowina 100%, pomidor, cebula, sałata, ogórek, sosy)', price: 19},
      {name: 'Cheese (Wołowina 100%, ser, pomidor, cebula, salata, ogórek, sosy)', price: 21},
    ]
  },
  {
    name: 'Przystawki',
    items: [
      {name: 'Frytki duże', price: 13},
      {name: 'Frytki małe', price: 9},
      {name: 'Frytki + Nuggetsy', price: 20},
      {name: 'Krążki cebulowe', price: 12},
    ]
  },
  {
    name: 'Kawa',
    items: [
      {name: 'Kawa z Mlekiem', price: 12},
      {name: 'Kawa Espresso', price: 10},
      {name: 'Kawa Capuccino', price: 12},
      {name: 'Kawa Mrożona', price: 15},
      {name: 'Kawa z Lodami', price: 16},
    ]
  },
  {
    name: 'Piwo',
    items: [
      {name: 'Butelka 0.5l', price: 8},
      {name: 'Somersby 0.4l', price: 8},
      {name: 'Lech 0.33l', price: 8},
      {name: 'Piwo lane', price: 12},
      {name: 'Koktajl piwny HIT', price: 15},
    ]
  },
  {
    name: 'Napoje',
    items: [
      {
        name: 'Sok 0.33l' +
          '\n(porzeczka, pomarańcza, jabłko, multiwitamina)', price: 5
      },
      {name: 'Pepsi / Pepsi max 0.5l', price: 8},
      {name: 'Pepsi 0.33l', price: 6},
      {name: 'Mirinda / 7up / Lipton 0.5l', price: 8},
      {name: 'Energetyk 0.25l', price: 7},
      {name: 'Woda 0.5l', price: 4},
    ]
  },
  {
    name: 'Napoje Orzeźwiające',
    items: [
      {name: 'Granita', price: 8},
      {name: 'Lemoniada', price: 12},
      {name: 'Koktajl piwny HIT', price: 15},
      {name: 'Kawa z lodami', price: 11},
    ]
  },
];

import React from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {FoodCategory} from "../../resources/menu";
import './FoodCategoryCard.css';

export const FoodCategoryCard: React.FC<{ category: FoodCategory }> = ({category}) => {
  return (
    <Card className="food-category-card">
      <CardContent>
        <Typography variant="h6" gutterBottom className="category-name">
          {category.name.toUpperCase()}
        </Typography>
        {category.items.map((item, index) => (
          <Typography key={index} variant="body1" color="textSecondary">
            {item.name}: {item.price} PLN
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};

